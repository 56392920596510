import { Button, Card, Divider, Form, Input, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import TextArea from "antd/es/input/TextArea";

const DeleteAccount = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <Typography className="text-xl font-semibold mb-4" align="left">
        Delete Account Request
      </Typography>
      <Card className="w-full max-w-lg p-4">
        <div className="min-h-[calc(100vh_-_305px)] flex flex-col justify-center">
          <Form
            name="raise request"
            layout="vertical"
            style={{
              maxWidth: 500,
              width: "100%",
            }}
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Form.Item label="Name" name="name">
              <Input size="large" />
              <span className="text-red-500">Please Enter a valid name</span>
            </Form.Item>
            <Form.Item label="Mobile Number" name="Mobile Number">
              <Input addonBefore="+974" size="large" />
              <span className="text-red-500">
                Please Enter a valid Mobile Number
              </span>
            </Form.Item>
            <Form.Item label="Email" name="email">
              <Input size="large" />
              <span className="text-red-500">Please Enter a valid email</span>
            </Form.Item>
            <Form.Item label="Reason" name="reason">
              <TextArea size="large" />
              <span className="text-red-500">Please Enter a valid reason</span>
            </Form.Item>
          </Form>
        </div>
        <div className="flex justify-end gap-3">
          <Button type="primary">Request</Button>
        </div>
      </Card>
    </div>
  );
};

export default DeleteAccount;
